<template>
  <section id="RaidersLockerRoomMobile">
    <SideBarNavMobile subsectionTitle="Raiders Locker Room" />
    <div class="mobile-overlay"></div>
    <Photos />
    <Layout />
	<EventPhotos />
  </section>
</template>

<script>
  import Photos from '@/components/sections/EventSpaces/RaidersLockerRoom/Photos'
  import Layout from '@/components/sections/EventSpaces/RaidersLockerRoom/Layout'
  import EventPhotos from '@/components/sections/EventSpaces/RaidersLockerRoom/EventPhotos'
  import SideBarNavMobile from '@/components/Nav/SideBarNavMobile'

  export default {
    name: "RaidersLockerRoomMobile",
    components: {
      Photos,
      Layout,EventPhotos,
      SideBarNavMobile
    }
  }
</script>

<style lang="scss" scoped>
  #RaidersLockerRoomMobile {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    background-image: url('~@/assets/images/MobileBG/Raider-Locker-Room-BG.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    margin-top: -60px;
    .mobile-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(270deg, rgba(0,0,0,0.83) 20%, rgba(0,0,0,0.92) 82%);
    }
  }
</style>
